import {
  GET_LITESECTION,
  SHOW_LITESECTION_LOADING,
  LITESECTION_ERROR,
  SHOW_EDIT_LITESECTION_LOADING,
  EDIT_LITESECTION,
  DELETE_LITESECTION,
  CREATE_LITESECTION,
  GET_LITESECTION_DETAILS,
} from '../actions/types';

/**
 *
 */

const initialState = {
  litesectionlist: [],
  litesectionID: null,
  litesectionDetails: null,
  litesectionLoading: false,
  editsectionLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITESECTION_LOADING:
      return {
        ...state,
        litesectionLoading: true,
      };
    case SHOW_EDIT_LITESECTION_LOADING:
      return {
        ...state,
        editsectionLoading: true,
      };
    case GET_LITESECTION:
      return {
        ...state,
        litesectionlist: payload,
        litesectionLoading: false,
      };
    case CREATE_LITESECTION:
      return {
        ...state,
        litesectionlist: [payload, ...state.litesectionlist],
        editsectionLoading: false,
      };
    case EDIT_LITESECTION:
      return {
        ...state,
        litesectionlist: state.litesectionlist.map((section) =>
          section._id === payload._id
            ? {
                ...section,
                sectionName: payload.sectionName,
                equipmentID: payload.equipmentID,
                downloadUrl: payload.downloadUrl,
              }
            : section
        ),
        editsectionLoading: false,
      };
    case GET_LITESECTION_DETAILS:
      return {
        ...state,
        litesectionDetails: payload,
      };
    case DELETE_LITESECTION:
      return {
        ...state,
        litesectionlist: state.litesectionlist.filter(
          (section) => section._id !== payload
        ),
        editsectionLoading: false,
        litesectionLoading: false,
      };
    case LITESECTION_ERROR:
      return {
        ...state,
        error: payload,
        litesectionLoading: false,
      };
    default:
      return state;
  }
}
