import {
  SHOW_LITESUPPLIER_LOADING,
  GET_LITESUPPLIER,
  LITESUPPLIER_ERROR,
  GET_LITESUPPLIER_DETAILS,
  SHOW_EDIT_LITESUPPLIER_LOADING,
  DELETE_LITESUPPLIER,
  CREATE_LITESUPPLIER,
  EDIT_LITESUPPLIER,
} from '../actions/types';

const initialState = {
  litesupplierlist: [],
  litesupplierID: null,
  litesupplierdetails: null,
  litesupplierLoading: false,
  editsupplierLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITESUPPLIER_LOADING:
      return {
        ...state,
        litesupplierLoading: true,
      };
    case SHOW_EDIT_LITESUPPLIER_LOADING:
      return {
        ...state,
        editsupplierLoading: true,
      };
    case GET_LITESUPPLIER:
      return {
        ...state,
        litesupplierlist: payload,
        litesupplierLoading: false,
      };
    case GET_LITESUPPLIER_DETAILS:
      return {
        ...state,
        litesupplierdetails: payload,
        litesupplierLoading: false,
        editsupplierLoading: false,
      };
    case CREATE_LITESUPPLIER:
      return {
        ...state,
        litesupplierlist: [payload, ...state.litesupplierlist],
        litesupplierLoading: false,
      };
    case EDIT_LITESUPPLIER:
      return {
        ...state,
        litesupplierlist: state.litesupplierlist.map((supplier) =>
          supplier._id === payload._id
            ? {
                ...supplier,
                supplierName: payload.supplierName,
                email: payload.email,
                phone: payload.phone,
                streetAddress: payload.streetAddress,
                category: payload.category,
                website: payload.website,
                description: payload.description,
                operatingIn: payload.operatingIn,
                postalCode: payload.postalCode,
                city: payload.city,
                country: payload.country,
              }
            : supplier
        ),
        supplierLoading: false,
      };
    case LITESUPPLIER_ERROR:
      return {
        ...state,
        error: payload,
        litesupplierLoading: false,
        editsupplierLoading: false,
      };
    case DELETE_LITESUPPLIER:
      return {
        ...state,
        litesupplierlist: state.litesupplierlist.filter(
          (supplier) => supplier._id !== payload
        ),
        litesupplierLoading: false,
        editsupplierLoading: false,
      };
    default:
      return state;
  }
}
