import { combineReducers } from 'redux';

/** Modifications
 * olain-tarcina-sc-681 - ymplite registration
 */

import auth from './auth';
import alert from './alert';
import theme from './theme';
import worklist from './worklist';
import group from './group';
import doc from './doc';
import history from './history';
import logbook from './logbook';
import subgroup from './subgroup';
import inventory from './inventory';
import profile from './profile';
import user from './user';
import guests from './guests';
import photo from './photo';
import boat from './boat';
import ympequipment from './ympequipment';
import ympjob from './ympjob';
import form from './form';
import subform from './subform';
import boatform from './boatform';
import location from './location';
import sparejob from './sparejob';
import taskgroup from './taskgroup';
import event from './event';
import watchkeeper from './watchkeeper';
import watchkeeplog from './watchkeeplog';
import hourevent from './hourevent';
import vacation from './vacation';
import boatsubform from './boatsubform';
import formarchive from './formarchive';
import team from './team';
import discussion from './discussion';
import project from './project';
import deck from './deck';
import room from './room';
import projecthistory from './projecthistory';
import spare from './spare';
import equipment from './equipment';
import sectionlist from './sectionlist';
import orderlist from './orderlist';
import supplier from './supplier';
import log from './log';
import logrecord from './logrecord';
import expense from './expense';
import merchant from './merchant';
import category from './category';
import subcategory from './subcategory';
import sales from './sales';
import yacht from './yacht';
import calendar from './calendar';
import management from './management';
import yachtmake from './yachtmake';
import part from './part';
import invoice from './invoice';
import notification from './notification';
import shipyard from './shipyard';
import shipyardhistory from './shipyardhistory';
import shipyarddoc from './shipyarddoc';
import plan from './plan';
import liteympboat from './liteympboat';
import liteboat from './liteboat';
import litegroup from './litegroup';
import liteworklist from './liteworklist';
import litedocs from './litedocs';
import litehistory from './litehistory';
import litesubgroup from './litesubgroup';
import liteinventory from './liteinventory';
import litelocation from './litelocation';
import litesparejob from './litesparejob';
import liteorderlist from './liteorderlist';
import litespare from './litespare';
import litesectionlist from './litesectionlist';
import liteequipment from './liteequipment';
import litesupplier from './litesupplier';
import litecalendar from './litecalendar';
import liteguest from './liteguest';
import litelog from './litelog';
import litelogrecord from './litelogrecord';
import litephoto from './litephoto';
import litemerchant from './litemerchant';

const reducers = combineReducers({
  auth,
  theme,
  alert,
  worklist,
  group,
  doc,
  history,
  logbook,
  subgroup,
  inventory,
  profile,
  user,
  guests,
  photo,
  boat,
  ympequipment,
  ympjob,
  form,
  subform,
  boatform,
  location,
  sparejob,
  taskgroup,
  event,
  watchkeeper,
  watchkeeplog,
  hourevent,
  vacation,
  boatsubform,
  formarchive,
  team,
  discussion,
  project,
  deck,
  room,
  projecthistory,
  spare,
  equipment,
  sectionlist,
  orderlist,
  supplier,
  log,
  logrecord,
  expense,
  merchant,
  category,
  subcategory,
  sales,
  yacht,
  calendar,
  management,
  yachtmake,
  part,
  invoice,
  notification,
  shipyard,
  shipyardhistory,
  shipyarddoc,
  plan,
  // Modification start - 09.05.2024 - olain-tarcina-sc-681
  liteympboat,
  liteboat,
  litegroup,
  liteworklist,
  litedocs,
  litehistory,
  litesubgroup,
  liteinventory,
  litelocation,
  litesparejob,
  liteorderlist,
  litespare,
  litesectionlist,
  liteequipment,
  litesupplier,
  litecalendar,
  liteguest,
  litelog,
  litelogrecord,
  // Modification end - 09.05.2024 - olain-tarcina-sc-681
  litephoto,
  litemerchant,
});

export default reducers;
