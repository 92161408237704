import {
  GET_LITESPARE,
  SHOW_LITESPARE_LOADING,
  LITESPARE_ERROR,
  SHOW_EDIT_LITESPARE_LOADING,
  EDIT_LITESPARE,
  DELETE_LITESPARE,
  CREATE_LITESPARE,
  GET_LITESPARE_DETAILS,
} from '../actions/types';

/**
 *
 */

const initialState = {
  litesparelist: [],
  litespareID: null,
  litespareDetails: null,
  litespareLoading: false,
  editSpareLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITESPARE_LOADING:
      return {
        ...state,
        litespareLoading: true,
      };
    case SHOW_EDIT_LITESPARE_LOADING:
      return {
        ...state,
        editSpareLoading: true,
      };
    case GET_LITESPARE:
      return {
        ...state,
        litesparelist: payload,
        litespareLoading: false,
      };
    case CREATE_LITESPARE:
      return {
        ...state,
        litesparelist: [payload, ...state.litesparelist],
        editSpareLoading: false,
      };
    case EDIT_LITESPARE:
      return {
        ...state,
        litesparelist: state.litesparelist.map((spare) =>
          spare._id === payload._id
            ? {
                ...spare,
                designation: payload.designation,
                partNo: payload.partNo,
                equipmentID: payload.equipmentID,
                sectionID: payload.sectionID,
                unit: payload.unit,
                fn: payload.fn,
                measurement: payload.measurement,
                quantity: payload.quantity,
                serailNo: payload.serailNo,
                standard: payload.standard,
                key: payload.key,
                description: payload.description,
              }
            : spare
        ),
        editSpareLoading: false,
      };
    case GET_LITESPARE_DETAILS:
      return {
        ...state,
        litespareDetails: payload,
      };
    case DELETE_LITESPARE:
      return {
        ...state,
        litesparelist: state.litesparelist.filter(
          (spare) => spare._id !== payload
        ),
        editSpareLoading: false,
        litespareLoading: false,
      };
    case LITESPARE_ERROR:
      return {
        ...state,
        error: payload,
        litespareLoading: false,
        editSpareLoading: false,
      };
    default:
      return state;
  }
}
