import {
  GET_SPARE_LITEEQUIPMENT,
  SHOW_SPARE_LITEEQUIPMENT_LOADING,
  SPARE_LITEEQUIPMENT_ERROR,
  SHOW_EDIT_SPARE_LITEEQUIPMENT_LOADING,
  EDIT_SPARE_LITEEQUIPMENT,
  DELETE_SPARE_LITEEQUIPMENT,
  CREATE_SPARE_LITEEQUIPMENT,
  GET_SPARE_LITEEQUIPMENT_DETAILS,
} from '../actions/types';

/**
 *
 */

const initialState = {
  liteequiplist: [],
  liteequipID: null,
  liteequipDetails: null,
  liteequipLoading: false,
  editequipLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_SPARE_LITEEQUIPMENT_LOADING:
      return {
        ...state,
        liteequipLoading: true,
      };
    case SHOW_EDIT_SPARE_LITEEQUIPMENT_LOADING:
      return {
        ...state,
        editequipLoading: true,
      };
    case GET_SPARE_LITEEQUIPMENT:
      return {
        ...state,
        liteequiplist: payload,
        liteequipLoading: false,
      };
    case CREATE_SPARE_LITEEQUIPMENT:
      return {
        ...state,
        liteequiplist: [payload, ...state.liteequiplist],
        editequipLoading: false,
      };
    case EDIT_SPARE_LITEEQUIPMENT:
      return {
        ...state,
        liteequiplist: state.liteequiplist.map((spare) =>
          spare._id === payload._id
            ? {
                ...spare,
                equipmentName: payload.equipmentName,
                department: payload.department,
              }
            : spare
        ),
        editequipLoading: false,
      };
    case GET_SPARE_LITEEQUIPMENT_DETAILS:
      return {
        ...state,
        liteequipDetails: payload,
      };
    case DELETE_SPARE_LITEEQUIPMENT:
      return {
        ...state,
        liteequiplist: state.liteequiplist.filter(
          (spare) => spare._id !== payload
        ),
        editequipLoading: false,
        liteequipLoading: false,
      };
    case SPARE_LITEEQUIPMENT_ERROR:
      return {
        ...state,
        error: payload,
        liteequipLoading: false,
      };
    default:
      return state;
  }
}
