import axios from 'axios';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SHOW_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from './types';
import setAuthToken from '../utils/setAuthToken';
import emailjs from '@emailjs/browser';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';

/** Modifications
 * olain-tarcina-sc-681 - ymplite registration
 */

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData, history) => async (dispatch) => {
  dispatch({
    type: SHOW_LOADING,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Modification start - 09.05.2024 - olain-tarcina-sc-681
  const SERVICE_ID = 'service_toqfoo9';
  const TEMPLATE_ID = 'template_5ps2c2e';
  const USER_ID = 'user_WtCjGRDorUo3I1OmblHuB';

  const firstName = formData.firstName;
  const lastName = formData.lastName;
  const username = formData.email;
  const boatID = formData.boatName;
  const liteuser = formData.liteuser;
  const password = formData.password;
  const othersboatName = formData.othersboatName;
  let liteBody;

  if (formData.boatName === 'others') {
    liteBody = JSON.stringify({
      boatName: othersboatName,
      status: 'Active',
    });
  } else {
    const liteympboatdetails = await axios.get(
      `/api/liteympboat/details/${boatID}`
    );
    const { data } = liteympboatdetails;

    liteBody = JSON.stringify({
      boatName: data.boatName,
      status: data.status,
      free: data.free,
      model: data.model,
      make: data.make,
      year: data.year,
      captain: data.captain,
      builder: data.builder,
      length: data.length,
      officialNumber: data.officialNumber,
      signalLetter: data.signalLetter,
      hullMaterial: data.hullMaterial,
      imoNumber: data.imoNumber,
      numberRegistry: data.numberRegistry,
      previousRegistry: data.previousRegistry,
      dateKeelLaid: data.dateKeelLaid,
      dateMeasured: data.dateMeasured,
      breadth: data.breadth,
      mouldedDepth: data.mouldedDepth,
      mouldedDraught: data.mouldedDraught,
      overallLength: data.overallLength,
      netTonnage: data.netTonnage,
      numberSeamen: data.numberSeamen,
      numberPassengers: data.numberPassengers,
      methodPropulsion: data.methodPropulsion,
      descriptionEngines: data.descriptionEngines,
      engineMakers: data.engineMakers,
      numberEngines: data.numberEngines,
      brakePower: data.brakePower,
      speedShip: data.speedShip,
      callSign: data.callSign,
      flagState: data.flagState,
      email: data.email,
      contactNumber: data.contactNumber,
      boatOrg: data.boatOrg,
      boatType: data.boatType,
      type: data.type,
      shipyard: data.shipyard,
      garbagerecord: data.garbagerecord,
      gmdss: data.gmdss,
      officiallogbook: data.officiallogbook,
      oilrecord: data.oilrecord,
      cayman: data.cayman,
      grossTonnage: data.grossTonnage,
      nameOfMaster: data.nameOfMaster,
      competencyCertificate: data.competencyCertificate,
      registeredOwner: data.registeredOwner,
      distinctLetters: data.distinctLetters,
      period: data.period,
      mmsi: data.mmsi,
      seaAreas: data.seaAreas,
      radioFacility: data.radioFacility,
      duplication: data.duplication,
      shoreBased: data.shoreBased,
      boatStamp: data.boatStamp,
    });
  }

  try {
    emailjs.init(USER_ID);
    const checkEmail = await axios.get(`/api/users/check-user/${username}`);

    if (checkEmail.status === 200) {
      const liteboat = await axios.post(
        `/api/liteboat/register-boat/${boatID}`,
        liteBody,
        config
      );
      const groups = await axios.get(`/api/litegroup/litegroup-all/${boatID}`);
      const worklists = await axios.get(
        `/api/liteworklist/liteworklist-all/${boatID}`
      );

      const createdGroups = [];
      if (liteboat.data) {
        const gmdsslog = await axios.post(
          `/api/litelog/official-gmdss/${liteboat.data._id}`,
          config
        );
        const oilrecordlog = await axios.post(
          `/api/litelog/official-oilrecord/${liteboat.data._id}`,
          config
        );

        if (gmdsslog && oilrecordlog) {
          const gmdss = gmdsslog.data._id;
          const oilrecord = oilrecordlog.data._id;

          const logbody = JSON.stringify({
            gmdss,
            oilrecord,
          });

          await axios.post(
            `/api/liteboat/register-boat/${liteboat.data._id}`,
            logbody,
            config
          );
        }

        for (const group of groups.data) {
          const groupBody = {
            name: group.name,
            location: group.location,
            modelNumber: group.modelNumber,
            serialNumber: group.serialNumber,
            manufacturer: group.manufacturer,
            remarks: group.remarks,
            critical: group.critical,
            equipmentFiles: group.equipmentFiles,
            boatID: liteboat.data._id,
          };

          const response = await axios.post(
            '/api/litegroup/create-litegroup',
            groupBody,
            config
          );
          createdGroups.push({
            oldGroupID: group._id,
            newGroupID: response.data._id,
          });
        }

        for (const worklist of worklists.data) {
          const newGroup = createdGroups.find(
            (g) => g.oldGroupID === worklist.groupID
          );
          if (newGroup) {
            const worklistBody = {
              ...worklist,
              groupID: newGroup.newGroupID,
              boatID: liteboat.data._id,
            };

            await axios.post(
              '/api/liteworklist/create-liteworklist',
              worklistBody,
              config
            );
          }
        }

        const body = JSON.stringify({
          username,
          liteuser,
          password,
          liteboatID: liteboat.data._id,
          boatName: liteboat.data.boatName,
        });

        const res = await axios.post('/api/users/lite-user', body, config);
        const userID = res.data._id;

        const profileBody = JSON.stringify({
          firstName,
          lastName,
          liteboatID: liteboat.data._id,
          boatName: liteboat.data.boatName,
          userID,
          newEdit: true,
          email: username,
          liteuser,
        });

        const profile = await axios.post(
          '/api/profile/lite-profile',
          profileBody,
          config
        );

        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });

        const fullName =
          firstName.charAt(0).toUpperCase() +
          firstName.slice(1) +
          ' ' +
          lastName.charAt(0).toUpperCase() +
          lastName.slice(1);
        const login = window.location.origin + `/auth/login`;

        const params = {
          boatname: liteboat.data.boatName,
          user: fullName,
          password: password,
          userEmail: username,
          login: login,
        };

        if (res.data && profile.data) {
          await emailjs.send(SERVICE_ID, TEMPLATE_ID, params);
          history.push({
            pathname: `${AUTH_PREFIX_PATH}/success`,
            state: { fullName },
          });
        }
      }
    }
    // Modification end - 09.05.2024 - olain-tarcina-sc-681
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login = (username, password) => async (dispatch) => {
  dispatch({
    type: SHOW_LOADING,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ username, password });

  try {
    const res = await axios.post('/api/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  //dispatch({ type: CLEAR_PROFILE });
  // Modification start - 30.11.2023 - olain-tarcina-sc-522
  localStorage.clear();
  // Modification end - 30.11.2023 - olain-tarcina-sc-522
  dispatch({ type: LOGOUT });
};
