import {
  GET_LITELOGS,
  LITELOG_ERROR,
  SHOW_LITELOG_LOADING,
  SHOW_EDIT_LITELOG_LOADING,
  GET_LITELOG_DETAILS,
  CREATE_LITELOG,
  EDIT_LITELOG,
  DELETE_LITELOG,
  DELETE_LITELOG_RECORDS,
} from '../actions/types';

const initialState = {
  liteloglist: [],
  litelogID: null,
  litelogDetails: null,
  litelogLoading: false,
  editliteLogLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITELOG_LOADING:
      return {
        ...state,
        litelogLoading: true,
      };
    case SHOW_EDIT_LITELOG_LOADING:
      return {
        ...state,
        editliteLogLoading: true,
      };
    case GET_LITELOGS:
      return {
        ...state,
        liteloglist: payload,
        litelogLoading: false,
      };
    case LITELOG_ERROR:
      return {
        ...state,
        error: payload,
        litelogLoading: false,
      };
    case GET_LITELOG_DETAILS:
      return {
        ...state,
        litelogDetails: payload,
        litelogLoading: false,
        editliteLogLoading: false,
      };
    case CREATE_LITELOG:
      return {
        ...state,
        liteloglist: [payload, ...state.liteloglist],
        editliteLogLoading: false,
        litelogLoading: false,
      };
    case EDIT_LITELOG:
      return {
        ...state,
        litelogDetails: payload,
        editliteLogLoading: false,
        litelogLoading: false,
      };
    case DELETE_LITELOG:
      return {
        ...state,
        liteloglist: state.liteloglist.filter((log) => log._id !== payload),
        litelogLoading: false,
        editliteLogLoading: false,
      };
    case DELETE_LITELOG_RECORDS:
      return {
        ...state,
        litelogDetails: payload,
        editliteLogLoading: false,
      };
    default:
      return state;
  }
}
