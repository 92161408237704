import {
  GET_LITEGUESTS,
  LITEGUESTS_ERROR,
  SHOW_LITEGUESTS_LOADING,
  GET_LITEGUEST_DETAILS,
  CREATE_LITEGUESTS_SUCCESS,
  SHOW_EDIT_LITEGUESTS_LOADING,
  GET_EDIT_LITEGUEST_DETAILS,
  DELETE_LITEGUEST,
} from '../actions/types';

const initialState = {
  liteguestlist: [],
  liteguestID: null,
  liteguestdetails: null,
  liteguestLoading: false,
  editguestloading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEGUESTS_LOADING:
      return {
        ...state,
        liteguestLoading: true,
      };
    case SHOW_EDIT_LITEGUESTS_LOADING:
      return {
        ...state,
        editguestLoading: true,
      };
    case GET_LITEGUESTS:
      return {
        ...state,
        liteguestlist: payload,
        liteguestLoading: false,
      };
    case GET_LITEGUEST_DETAILS:
      return {
        ...state,
        liteguestdetails: payload,
        liteguestLoading: false,
      };
    case GET_EDIT_LITEGUEST_DETAILS:
      return {
        ...state,
        liteguestdetails: payload,
        editguestLoading: false,
      };
    case CREATE_LITEGUESTS_SUCCESS:
      return {
        ...state,
        gliteuestlist: [payload, ...state.liteguestlist],
        liteguestLoading: false,
      };
    case DELETE_LITEGUEST:
      return {
        ...state,
        liteguestlist: state.liteguestlist.filter(
          (guest) => guest._id !== payload
        ),
      };
    case LITEGUESTS_ERROR:
      return {
        ...state,
        error: payload,
        liteguestLoading: false,
      };
    default:
      return state;
  }
}
