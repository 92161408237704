import {
  GET_LITEMERCHANTS,
  LITEMERCHANT_ERROR,
  SHOW_LITEMERCHANT_LOADING,
  SHOW_EDIT_LITEMERCHANT_LOADING,
  GET_LITEMERCHANT_DETAILS,
  CREATE_LITEMERCHANT,
  EDIT_LITEMERCHANT,
  DELETE_LITEMERCHANT,
} from '../actions/types';

/**
 * olain-tarcina-sc-681:
 */

const initialState = {
  litemerchantlist: [],
  litemerchantID: null,
  litemerchantDetails: null,
  litemerchantLoading: false,
  editLitemerchantLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEMERCHANT_LOADING:
      return {
        ...state,
        litemerchantLoading: true,
      };
    case SHOW_EDIT_LITEMERCHANT_LOADING:
      return {
        ...state,
        editLitemerchantLoading: true,
      };
    case GET_LITEMERCHANTS:
      return {
        ...state,
        litemerchantlist: payload,
        litemerchantLoading: false,
      };
    case LITEMERCHANT_ERROR:
      return {
        ...state,
        error: payload,
        litemerchantLoading: false,
      };
    case GET_LITEMERCHANT_DETAILS:
      return {
        ...state,
        litemerchantDetails: payload,
        litemerchantLoading: false,
        editLitemerchantLoading: false,
      };
    case CREATE_LITEMERCHANT:
      return {
        ...state,
        litemerchantlist: [payload, ...state.litemerchantlist],
        editLitemerchantLoading: false,
        litemerchantLoading: false,
      };
    case EDIT_LITEMERCHANT:
      return {
        ...state,
        litemerchantDetails: payload,
        litemerchantlist: state.litemerchantlist.map((litemerchant) =>
          litemerchant._id === payload._id
            ? {
                ...litemerchant,
                name: payload.name,
              }
            : litemerchant
        ),
        editLitemerchantLoading: false,
        litemerchantLoading: false,
      };
    case DELETE_LITEMERCHANT:
      return {
        ...state,
        litemerchantlist: state.litemerchantlist.filter(
          (log) => log._id !== payload
        ),
        litemerchantLoading: false,
        editLitemerchantLoading: false,
      };
    default:
      return state;
  }
}
