import {
  SHOW_LITECALENDAR_LOADING,
  GET_LITECALENDAR,
  LITECALENDAR_ERROR,
  EDIT_LITECALENDAR,
  CREATE_LITECALENDAR_SUCCESS,
  DELETE_LITECALENDAR,
} from '../actions/types';

/**
 */

const initialState = {
  litecalendarlist: [],
  litecalendarLoading: false,
  editCalendarLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITECALENDAR_LOADING:
      return {
        ...state,
        litecalendarLoading: true,
      };
    case GET_LITECALENDAR:
      return {
        ...state,
        litecalendarlist: payload,
        litecalendarLoading: false,
      };
    case EDIT_LITECALENDAR:
      return {
        ...state,
        litecalendarlist: state.litecalendarlist.map((event) =>
          event._id === payload._id
            ? {
                ...event,
                title: payload.title,
                start: payload.start,
                end: payload.end,
                allDay: payload.allDay,
                type: payload.type,
                department: payload.department,
                borderColor: payload.borderColor,
              }
            : event
        ),
        editCalendarLoading: false,
        litecalendarLoading: false,
      };
    case CREATE_LITECALENDAR_SUCCESS:
      return {
        ...state,
        litecalendarlist: [payload, ...state.litecalendarlist],
        litecalendarLoading: false,
      };
    case LITECALENDAR_ERROR:
      return {
        ...state,
        error: payload,
        litecalendarLoading: false,
      };
    case DELETE_LITECALENDAR:
      return {
        ...state,
        litecalendarlist: state.litecalendarlist.filter(
          (event) => event._id !== payload
        ),
        calendarLoading: false,
      };
    default:
      return state;
  }
}
