import {
  GET_LITEORDER,
  SHOW_LITEORDER_LOADING,
  LITEORDER_ERROR,
  SHOW_EDIT_LITEORDER_LOADING,
  EDIT_LITEORDER,
  DELETE_LITEORDER,
  CREATE_LITEORDER,
  GET_LITEORDER_DETAILS,
  GET_LITEORDERLIST,
} from '../actions/types';

/**
 *
 */

const initialState = {
  liteorderlist: [],
  liteorderID: null,
  liteorderDetails: null,
  liteorderLoading: false,
  editorderLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEORDER_LOADING:
      return {
        ...state,
        liteorderLoading: true,
      };
    case SHOW_EDIT_LITEORDER_LOADING:
      return {
        ...state,
        editorderLoading: true,
      };
    case GET_LITEORDER:
      return {
        ...state,
        liteorderlist: payload,
        liteorderLoading: false,
      };
    case CREATE_LITEORDER:
      return {
        ...state,
        liteorderDetails: payload,
        editorderLoading: false,
      };
    case EDIT_LITEORDER:
      return {
        ...state,
        liteorderDetails: payload,
        editorderLoading: false,
        liteorderLoading: false,
      };
    case GET_LITEORDER_DETAILS:
      return {
        ...state,
        liteorderDetails: payload,
        liteorderLoading: false,
      };
    case GET_LITEORDERLIST:
      return {
        ...state,
        liteorderlist: payload,
        liteorderLoading: false,
      };
    case DELETE_LITEORDER:
      return {
        ...state,
        liteorderlist: state.liteorderlist.filter(
          (spare) => spare._id !== payload
        ),
        editorderLoading: false,
        liteorderLoading: false,
      };
    case LITEORDER_ERROR:
      return {
        ...state,
        error: payload,
        liteorderLoading: false,
        editorderLoading: false,
      };
    default:
      return state;
  }
}
