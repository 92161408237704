import {
  GET_LITESPAREJOBS,
  LITESPAREJOBS_ERROR,
  SHOW_LITESPAREJOBS_LOADING,
  CREATE_LITESPAREJOBS_SUCCESS,
  DELETE_LITESPAREJOBS,
} from '../actions/types';

const initialState = {
  litesparejoblist: [],
  litesparejobID: null,
  litesparejobdetails: null,
  litespareLoading: false,
  editSpareLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITESPAREJOBS_LOADING:
      return {
        ...state,
        litespareLoading: true,
      };
    case GET_LITESPAREJOBS:
      return {
        ...state,
        litesparejoblist: payload,
        litespareLoading: false,
      };
    case CREATE_LITESPAREJOBS_SUCCESS:
      return {
        ...state,
        litesparejoblist: [payload, ...state.litesparejoblist],
        litespareLoading: false,
      };
    case DELETE_LITESPAREJOBS:
      return {
        ...state,
        litesparejoblist: state.litesparejoblist.filter(
          (sparejob) => sparejob._id !== payload
        ),
        litespareLoading: false,
      };
    case LITESPAREJOBS_ERROR:
      return {
        ...state,
        error: payload,
        litespareLoading: false,
      };
    default:
      return state;
  }
}
