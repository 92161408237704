import {
  GET_LITELOCATION,
  SHOW_LITELOCATION_LOADING,
  LITELOCATION_ERROR,
  GET_LITELOCATION_DETAILS,
  SHOW_EDIT_LITELOCATION_LOADING,
  CREATE_LITELOCATION,
  EDIT_LITELOCATION,
  DELETE_LITELOCATION,
} from '../actions/types';

const initialState = {
  litelocationlist: [],
  litelocationID: null,
  litelocationdetails: null,
  litelocationLoading: false,
  editLocLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITELOCATION_LOADING:
      return {
        ...state,
        litelocationLoading: true,
      };
    case SHOW_EDIT_LITELOCATION_LOADING:
      return {
        ...state,
        editLocLoading: true,
      };
    case GET_LITELOCATION:
      return {
        ...state,
        litelocationlist: payload,
        litelocationLoading: false,
      };
    case CREATE_LITELOCATION:
      return {
        ...state,
        litelocationlist: [payload, ...state.litelocationlist],
        editLocLoading: false,
      };
    case EDIT_LITELOCATION:
      return {
        ...state,
        litelocationlist: state.litelocationlist.map((LOCATION) =>
          LOCATION._id === payload._id
            ? {
                ...LOCATION,
                name: payload.name,
              }
            : LOCATION
        ),
        editLocLoading: false,
      };
    case GET_LITELOCATION_DETAILS:
      return {
        ...state,
        litelocationdetails: payload,
      };
    case DELETE_LITELOCATION:
      return {
        ...state,
        litelocationlist: state.litelocationlist.filter(
          (location) => location._id !== payload
        ),
        litelocationLoading: false,
      };
    case LITELOCATION_ERROR:
      return {
        ...state,
        error: payload,
        litelocationLoading: false,
      };
    default:
      return state;
  }
}
