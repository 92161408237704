import {
  GET_LITELOGRECORD,
  GET_LITELOGRECORD_DETAILS,
  LITELOGRECORD_ERROR,
  SHOW_LITELOGRECORD_LOADING,
  SHOW_EDIT_LITELOGRECORD_LOADING,
  CREATE_LITELOGRECORD,
  EDIT_LITELOGRECORD,
  DELETE_LITELOGRECORD,
} from '../actions/types';

/**
 *
 */

const initialState = {
  litelogrecordlist: [],
  litelogrecordID: null,
  litelogrecordDetails: null,
  litelogrecordLoading: false,
  editLogrecordLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITELOGRECORD_LOADING:
      return {
        ...state,
        litelogrecordLoading: true,
      };
    case SHOW_EDIT_LITELOGRECORD_LOADING:
      return {
        ...state,
        editLogrecordLoading: true,
      };
    case GET_LITELOGRECORD:
      return {
        ...state,
        litelogrecordlist: payload,
        litelogrecordLoading: false,
      };
    case CREATE_LITELOGRECORD:
      return {
        ...state,
        litelogrecordlist: [payload, ...state.litelogrecordlist],
        editLogrecordLoading: false,
      };
    case EDIT_LITELOGRECORD:
      return {
        ...state,
        litelogrecordlist: state.litelogrecordlist.map((logrecord) =>
          logrecord._id === payload._id
            ? {
                ...logrecord,
                name: payload.name,
              }
            : logrecord
        ),
        editLogrecordLoading: false,
        litelogrecordDetails: payload,
      };
    case GET_LITELOGRECORD_DETAILS:
      return {
        ...state,
        litelogrecordDetails: payload,
        litelogrecordLoading: false,
      };
    case DELETE_LITELOGRECORD:
      return {
        ...state,
        litelogrecordlist: state.litelogrecordlist.filter(
          (location) => location._id !== payload
        ),
        litelogrecordLoading: false,
      };
    case LITELOGRECORD_ERROR:
      return {
        ...state,
        error: payload,
        litelogrecordLoading: false,
      };
    default:
      return state;
  }
}
