import {
  LITEPHOTOS_ERROR,
  SHOW_LITEPHOTOS_LOADING,
  UPLOAD_LITEPHOTO_SUCCESS,
  DELETE_LITEPHOTO,
  GET_LITEPHOTOS,
  GET_INVENTORY_LITEPHOTOS,
  UPLOAD_INVETORY_LITEPHOTO_SUCCESS,
} from '../actions/types';

/**
 * olain-tarcina-sc-681:
 * olain-tarcina-sc-715: Logbook - YMP Lite
 */

const initialState = {
  litephotolist: [],
  litephotoID: null,
  litephotoDetails: null,
  litephotoLoading: false,
  uploading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEPHOTOS_LOADING:
      return {
        ...state,
        litephotoLoading: true,
      };
    case UPLOAD_LITEPHOTO_SUCCESS:
      return {
        ...state,
        // modification start - 12.02.2024 - olain-tarcina-sc-715
        litephotolist: [...payload, ...state.litephotolist],
        // modification end - 12.02.2024 - olain-tarcina-sc-715
        litephotoLoading: false,
      };
    case UPLOAD_INVETORY_LITEPHOTO_SUCCESS:
      return {
        ...state,
        litephotolist: state.litephotolist.concat(
          payload.map((litephoto) => ({
            _id: litephoto._id,
            url: litephoto.downloadurl,
            inventoryID: litephoto.inventoryID,
          }))
        ),
        litephotoLoading: false,
      };
    case GET_LITEPHOTOS:
      return {
        ...state,
        litephotolist: payload,
        litephotoLoading: false,
      };
    case GET_INVENTORY_LITEPHOTOS:
      return {
        ...state,
        litephotolist: payload.map((litephoto) => ({
          _id: litephoto._id,
          url: litephoto.downloadurl,
          inventoryID: litephoto.inventoryID,
        })),
        litephotoLoading: false,
      };
    case LITEPHOTOS_ERROR:
      return {
        ...state,
        error: payload,
        litephotoLoading: false,
      };
    case DELETE_LITEPHOTO:
      return {
        ...state,
        litephotolist: state.litephotolist.filter(
          (imageitem) => imageitem._id !== payload
        ),
        litephotoLoading: false,
      };
    default:
      return state;
  }
}
